import React from 'react'

import { TableWrapper, TableElement } from './styles'

const Table = ({ children }) => {
  return (
    <TableWrapper>
      <TableElement>
        <tbody>{children}</tbody>
      </TableElement>
    </TableWrapper>
  )
}

export default Table
