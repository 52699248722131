import React from 'react'
import { Link } from 'gatsby'

import formatSlug from '../../formatSlug'
import { Container, InfoIcon, LinkText, Root, Title, Type } from './styles'

const Notification = ({ badgeText, link, linkText, title, variant }) => {
  const content = (
    <Root variant={variant}>
      <Container>
        <Type>
          <InfoIcon />
          {badgeText}
        </Type>
        <Title>{title}</Title>
        <LinkText>{linkText}</LinkText>
      </Container>
    </Root>
  )
  return link.slug ? (
    <Link to={formatSlug(link.slug)}>{content}</Link>
  ) : (
    <a target="_blank" rel="noopener noreferrer" href={link.itemLink}>
      {content}
    </a>
  )
}

export default Notification
