import { useEffect, useReducer } from 'react'

// Reactive Vars are a concept popularized in React by Apollo Client. A reactive
// var is a var created at the top level of a file using the `makeVar` function
// below. It holds a tiny amount of global state that can be updated and
// accessed anywhere. Unlike most alternative solutions, it only triggers a
// rerender of subscribed components and not a single top-level component. This
// avoids the majority of the performance issues in React caused by global
// state. It's also a great way to isolate the global state to within components
// that define it and components that use it. In other words, we don't need to
// maintain a separate global file with all global states mixed together.

// The code below comes originally from https://github.com/jorbuedo/react-reactive-var.
// Since the library only has 2 stars and the below code is all it takes, I felt
// it better to maintain a copy.

// See the CED project for a fully typed version.

// https://www.apollographql.com/docs/react/local-state/reactive-variables/

export const makeVar = (initialValue, equalsFunc) => {
  let value = initialValue
  const subscribers = new Set()

  const reactiveVar = (newValue) => {
    if (newValue !== undefined) {
      let nextValue = value

      if (newValue instanceof Function) {
        nextValue = newValue(value)
      } else {
        nextValue = newValue
      }

      const valueChanged = equalsFunc
        ? !equalsFunc(nextValue, value)
        : nextValue !== value
      value = nextValue

      if (valueChanged) {
        subscribers.forEach((handler) => handler(value))
      }
    }
    return value
  }

  reactiveVar.subscribe = (handler) => {
    subscribers.add(handler)
    return () => subscribers.delete(handler)
  }

  reactiveVar.unsubscribe = (handler) => {
    subscribers.delete(handler)
  }

  return reactiveVar
}

export const useReactiveVar = (reactiveVar) => {
  const handler = useReducer((x) => x + 1, 0)[1]

  useEffect(() => {
    reactiveVar.subscribe(handler)
    return () => reactiveVar.unsubscribe(handler)
  }, [reactiveVar, handler])

  return reactiveVar()
}
