import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

export const Root = styled.section(
  ({ theme: { breakpoints, colors } }) => css`
    background-color: ${colors.white};
    border-radius: 8px;
    width: 375px;

    @media (min-width: ${breakpoints.l}) {
      border: 1px solid #e3e3e3;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.0971467);
      width: 390px;
    }
  `,
)
export const Header = styled.header(
  ({ theme: { breakpoints } }) => css`
    align-items: center;

    display: flex;
    padding: 22px 16px;

    @media (min-width: ${breakpoints.l}) {
      border-bottom: 1px solid #e3e3e3;
      padding: 18px 21px 15px;
    }
  `,
)

export const List = styled.ul(
  ({ theme: { breakpoints } }) => css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 12px;
    margin: 0;
    padding: 0 0 0 16px;

    @media (min-width: ${breakpoints.l}) {
      padding: 21px;
    }
  `,
)
export const ListItem = styled.li`
  //TODO: move this color to the theme
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  display: flex;
  height: 112px;
  width: 107px;
`
export const Logout = styled.a(
  ({ theme: { breakpoints, colors, fonts } }) => css`
    display: none;

    @media (min-width: ${breakpoints.l}) {
      display: block;
      background: none;
      border: 0;
      color: ${colors.greyDark};
      cursor: pointer;
      flex: 0 0 auto;
      font-family: ${fonts.adelleSans.regular};
      font-style: normal;
      font-size: 14px;
      line-height: 1.79;
      padding: 0;
    }
  `,
)

export const LinkInternal = styled(GatsbyLink)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const LinkExternal = styled.a`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`
export const LinkText = styled.div(
  ({ theme: { colors, fonts } }) => css`
    color: ${colors.black};
    font-family: ${fonts.adelle.bold};
    font-size: 12px;
    line-height: 1.4;
    padding: 6px 6px 0;
    text-align: center;
  `,
)

export const UserImage = styled.img`
  //TODO: move this color to the theme
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  flex: 0 0 auto;
  height: 43px;
  margin-right: 16px;
  width: 54px;
  object-fit: cover;
`
export const UserName = styled.div(
  ({ theme: { fonts } }) => css`
    flex: 1 1 auto;
    font-family: ${fonts.adelle.regular};
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  `,
)
export const MobileLogout = styled.a(
  ({ theme: { breakpoints, colors, fonts } }) => css`
    background: ${colors.greyLightest};
    border-radius: 16px;
    color: ${colors.red};
    display: block;
    font-family: ${fonts.adelle.regular};
    font-size: 14px;
    line-height: 19px;
    margin: 30px 16px;
    padding: 20px;
    text-align: center;
    width: 345px;

    @media (min-width: ${breakpoints.l}) {
      display: none;
    }
  `,
)
