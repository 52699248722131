import styled from 'styled-components'

export const Image = styled.img`
  max-width: 149px;
`

export const FileList = styled.ul`
  list-style: none;
  margin: 22px 0;
  padding: 0;
`
export const FileListItem = styled.li`
  margin-bottom: 24px;
`
