// @ts-nocheck
import { css } from 'styled-components'

import AdelleBold from './Adelle-Bold.woff2'
import AdelleRegular from './Adelle-Regular.woff2'
import AdelleLight from './Adelle-Light.woff2'
import AdelleSansRegular from './AdelleSans-Regular.woff2'
import AdelleSansLight from './AdelleSans-Light.woff2'
import ImpactURW from './ImpactURW.woff2'

export const fontCss = css`
  @font-face {
    font-display: swap;
    font-family: 'AdelleBold';
    src: url(${AdelleBold});
  }

  @font-face {
    font-display: swap;
    font-family: 'AdelleRegular';
    src: url(${AdelleRegular});
  }

  @font-face {
    font-display: swap;
    font-family: 'AdelleLight';
    src: url(${AdelleLight});
  }

  @font-face {
    font-display: swap;
    font-family: 'AdelleSansRegular';
    src: url(${AdelleSansRegular});
  }

  @font-face {
    font-display: swap;
    font-family: 'AdelleSansLight';
    src: url(${AdelleSansLight});
  }

  @font-face {
    font-display: swap;
    font-family: 'ImpactURW';
    src: url(${ImpactURW});
  }
`
