module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_@babel+core@7.25._kfwv5nxcrngrcg62q3ry53pn3a/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../gatsby-theme-quantore/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_5t4snwheha4ow74q62xjzgbo4a/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Quantore.com","short_name":"Quantore","start_url":"/","background_color":"#3C0002","theme_color":"#3C0002","display":"standalone","icon":"./src/faviconData/512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f2298079359b7fb9eb100c2f2706a23e"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-breadcrumb@12.3.2_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18_fkp7zto77mns4uly73smgnyb6e/node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Consumenten"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__react_ism6ofmn35wcqjobqx3nzf22gy/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MFSRWVF","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__lpj6j6pqt6cs636rjl45ud64le/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
