import React from 'react'
import { useMedia } from 'react-use-media'
import { Link } from 'gatsby'
import { formatSlug } from '../../utils'
import FaqItem from '../FaqList/FaqItem'
import Theme from '../../theme'
import Typography from '../Typography/Typography'

import { SubFooterWrapper, SubFooterRow, SubFooterCol } from './styles'

const MenuItem = ({ id, title, slug }) => (
  <li key={id}>
    {slug !== '' ? (
      <Link to={formatSlug(slug)}>
        <Typography variant="body" lineHeight="28px" tag="span">
          {title}
        </Typography>
      </Link>
    ) : (
      <span>
        <Typography variant="body" lineHeight="28px" tag="span">
          {title}
        </Typography>
      </span>
    )}
  </li>
)

const MenuList = ({ id: menuId, subitems }) => (
  <ul>
    {subitems.map(({ id, title, link }) => (
      <MenuItem
        key={`${menuId}-${id}`}
        title={title}
        slug={link ? formatSlug(link.slug) : ''}
      />
    ))}
  </ul>
)

const FooterTop = ({ submenus }) => {
  const isMobile = useMedia({
    maxWidth: Theme.breakpoints.m,
  })

  return (
    submenus.length && (
      <SubFooterWrapper>
        <SubFooterRow>
          {submenus.map(({ id, title, subitems }) => (
            <React.Fragment key={id}>
              {isMobile ? (
                <FaqItem title={title}>
                  {subitems && <MenuList id={id} subitems={subitems} />}
                </FaqItem>
              ) : (
                <SubFooterCol>
                  <Typography variant="headerSmall" tag="div">
                    {title}
                  </Typography>

                  {subitems && <MenuList subitems={subitems} />}
                </SubFooterCol>
              )}
            </React.Fragment>
          ))}
        </SubFooterRow>
      </SubFooterWrapper>
    )
  )
}

export default FooterTop
