import React from 'react'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

import { formatSlug } from '../../utils'
import Container from '../Container'
import { Wrapper, ContainerInner, StyledLink, StyledLinkDummy } from './styles'

const isPartiallyActive = (slug, entries, pathname) => {
  if (slug === '/') {
    return !entries.some((item) => pathname.includes(item))
  }
  return pathname.includes(slug)
}

const SiteSwitch = () => {
  const {
    contentfulMenu: { subitems },
  } = useStaticQuery(
    graphql`
      query MyQuery {
        contentfulMenu(contentful_id: { eq: "5BIyks5rnkjhT0vzY0ioir" }) {
          subitems {
            id
            title
            link {
              ... on Node {
                id
                ... on ContentfulDetailPage {
                  slug
                }
                ... on ContentfulPage {
                  slug
                }
              }
            }
          }
        }
      }
    `,
  )

  const { pathname } = useLocation()
  const slugs = subitems
    .map(({ link: { slug } }) => formatSlug(slug))
    .filter((slug) => slug !== '/')

  return (
    <Wrapper>
      <Container>
        <ContainerInner>
          {subitems.map(({ id, title, link }) => (
            <React.Fragment key={id}>
              {link ? (
                <StyledLink
                  fade="true"
                  duration={2}
                  to={formatSlug(link.slug)}
                  activeClassName="active"
                  partiallyActive={isPartiallyActive(
                    formatSlug(link.slug),
                    slugs,
                    pathname,
                  )}
                >
                  {title}
                </StyledLink>
              ) : (
                <StyledLinkDummy>{title}</StyledLinkDummy>
              )}
            </React.Fragment>
          ))}
        </ContainerInner>
      </Container>
    </Wrapper>
  )
}

export default SiteSwitch
