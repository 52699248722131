import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.greyLightest};
`

export const ContainerInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    justify-content: flex-end;
  }
`

const sharedStyles = ({ theme: { breakpoints, colors, fonts } }) => css`
  color: ${colors.greyDark};
  font-family: ${fonts.adelle.regular};
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  position: relative;
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${colors.black};
  }

  &.active {
    color: ${colors.black};

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      background-color: ${colors.brand};
    }
  }

  @media (min-width: ${breakpoints.l}) {
    margin-right: 2em;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const StyledLink = styled(Link)`
  ${
    // @ts-ignore
    sharedStyles
  }
`

export const StyledLinkDummy = styled.span`
  ${sharedStyles}
`
