/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { formatSlug } from '../../utils'
import { Orb3, Chevron } from '../../images/svg/Icons'

import {
  StyledHyperlink,
  StyledLink,
  ButtonInner,
  ButtonText,
  ArrowButton,
  ChevronButton,
  StyledArrowRight,
  RealButton,
} from './styles'

const Button = ({
  size = 's',
  content,
  color,
  wide,
  hyperlink,
  to,
  arrow,
  cover,
  direction = 'right',
  target = '_blank',
  variation = 'primary',
  renderAs,
  ...rest
}) => {
  const ButtonContent = () => {
    if (variation === 'primary' || variation === 'secondary') {
      return content ? (
        <ButtonInner wide={wide} variation={variation} content={content}>
          <ButtonText arrow={arrow}>{content}</ButtonText>

          {arrow && (
            <StyledArrowRight hyperlink={hyperlink} content={content} />
          )}
        </ButtonInner>
      ) : (
        <ArrowButton color={color}>
          <Orb3 className="button-background" />
          <StyledArrowRight
            className="chevron-icon"
            hyperlink={hyperlink}
            content={content}
          />
        </ArrowButton>
      )
    } else {
      return (
        <ChevronButton direction={direction}>
          <Orb3 className="button-background" />
          <Chevron className="chevron-icon" size="32" direction={direction} />
        </ChevronButton>
      )
    }
  }

  const RenderLink = () => {
    if (hyperlink) {
      return (
        <StyledHyperlink
          wide={wide}
          size={size}
          color={color}
          variation={variation}
          href={hyperlink}
          target={target}
          content={content}
          covering={cover}
          rel="noopener noreferrer"
          aria-label={content ?? 'Lees meer'}
          {...rest}
        >
          <ButtonContent />
        </StyledHyperlink>
      )
    } else {
      return (
        <StyledLink
          to={formatSlug(to)}
          fade="true"
          duration={2}
          wide={wide}
          size={size}
          color={color}
          variation={variation}
          content={content}
          covering={cover}
          aria-label={content ?? 'Lees meer'}
          {...rest}
        >
          <ButtonContent />
        </StyledLink>
      )
    }
  }

  return renderAs === 'button' ? (
    <RealButton
      wide={wide}
      size={size}
      color={color}
      variation={variation}
      content={content}
      covering={cover}
      {...rest}
    >
      <ButtonContent />
    </RealButton>
  ) : (
    <RenderLink />
  )
}

/* Deprecated in React 18 */
/*
Button.propTypes = {
  size: PropTypes.string,
  content: PropTypes.string,
  color: PropTypes.string,
  arrow: PropTypes.string,
  covering: PropTypes.oneOf(['true', 'false']),
  wide: PropTypes.string,
  hyperlink: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'right', 'down', 'left']),
  variation: PropTypes.oneOf(['primary', 'secondary', 'chevron']),
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
}
*/

/* eslint react/require-default-props: "off"  */
// Button.defaultProps = {
//   size: 's',
//   variation: 'primary',
//   target: '_blank',
//   direction: 'right',
// }

export default Button
