import styled, { css } from 'styled-components'

import { Info } from '../../images/svg/Icons'

export const InfoIcon = styled(Info)`
  margin-right: 7px;
  vertical-align: sub;
`

export const Type = styled.div(
  ({ theme: { breakpoints, colors, fonts } }) => css`
    color: ${colors.white};
    font-family: ${fonts.adelle.bold};
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 9px;

    @media (min-width: ${breakpoints.l}) {
      flex: 0 0 auto;
      margin-bottom: 0;
    }
  `,
)

export const Container = styled.div(
  ({ theme: { breakpoints, grid } }) => css`
    padding: 16px;

    @media (min-width: ${breakpoints.l}) {
      align-items: center;
      display: flex;
      max-width: ${grid.m};
      margin: 0 auto;
    }
  `,
)

export const Root = styled.section(
  ({ theme: { colors }, variant }) => css`
    background-color: ${variant === 'error' ? colors.red : colors.orange};
  `,
)

export const Title = styled.div(
  ({ theme: { breakpoints, colors } }) => css`
    color: ${colors.white};
    margin-bottom: 9px;
    font-size: 12px;
    line-height: 1.4;

    @media (min-width: ${breakpoints.l}) {
      flex: 1 1 auto;
      font-size: 14px;
      line-height: 22px;
      margin: 0 32px;
    }
  `,
)

export const LinkText = styled.div(
  ({ theme: { breakpoints, colors, fonts } }) => css`
    color: ${colors.white};
    font-family: ${fonts.adelleSans.regular};
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    text-decoration: underline;

    @media (min-width: ${breakpoints.l}) {
      flex: 0 0 auto;
      font-size: 14px;
      line-height: 22px;
    }
  `,
)
