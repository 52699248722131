const formatSlug = (slug, removeLeadingSlash = false, stylize = false) => {
  let formattedSlug = slug

  if (stylize) {
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
    const to = 'aaaaaeeeeeiiiiooooouuuunc------'

    formattedSlug = slug
      .split('')
      .map((letter, i) =>
        letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)),
      )

    formattedSlug = formattedSlug
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/&/g, '-en-')
      .replace(/[^\w-/]+/g, '')
      .replace(/--+/g, '-')
  }

  if (removeLeadingSlash && formattedSlug.startsWith('/')) {
    formattedSlug = formattedSlug.substr(1)
  } else if (!removeLeadingSlash && !formattedSlug.startsWith('/')) {
    formattedSlug = `/${formattedSlug}`
  }

  return formattedSlug
}

module.exports = formatSlug
