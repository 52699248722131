import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import QuickMenu from '../QuickMenu/QuickMenu'
import Settings from '../../settings'

export const MenuWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['sticky'].includes(prop),
})`
  position: absolute;
  display: inline-block;
  left: 0;
  top: ${Settings.hasSiteSwitch ? '102px' : '60px'};
  width: 100%;
  height: ${Settings.hasSiteSwitch
    ? 'calc(100dvh - 102px)'
    : 'calc(100dvh - 60px)'};
  padding-top: 30px;
  background-color: ${(props) => props.theme.colors.backgroundColor};
  overflow: auto;
  overscroll-behavior: none;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    overflow: visible;
    position: relative;
    display: block;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    padding-top: 0;
    background-color: transparent;
    margin-left: ${({ sticky }) => {
      if (sticky && Settings.headerLogoOverflowMode !== 'none') {
        return '60px'
      } else if (Settings.headerLogoOverflowMode === 'none') {
        return '30px'
      } else {
        return '118px'
      }
    }};
    transition: margin 0.3s ease-out;

    &.breadcrumb-visible {
      top: 0;
    }
  }
`

export const ToggleText = styled.div(
  ({ theme: { breakpoints, colors, fonts } }) => css`
    color: ${colors.black};
    display: block;
    font-family: ${fonts.adelle.bold};
    font-size: 28px;
    padding: 10px 0;
    position: relative;
    text-decoration: none;

    &.menu-item--active {
      color: ${colors.brand};
    }

    &:hover,
    &:focus {
      color: ${colors.brand};
    }

    @media (min-width: ${breakpoints.l}) {
      font-size: 20px;
      line-height: 30px;
    }
  `,
)

export const sharedMainLink = ({
  theme: { breakpoints, colors, fonts },
}) => css`
  color: ${colors.black};
  display: block;
  font-size: 28px;
  font-family: ${fonts.adelle.bold};
  padding: 10px 0;
  text-decoration: none;
  position: relative;

  &:hover,
  &:focus {
    color: ${colors.brand};
  }

  @media (min-width: ${breakpoints.l}) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const MainLink = styled(Link)`
  ${sharedMainLink}

  &.menu-item--active {
    color: ${(props) => props.theme.colors.brand};
  }
`

export const MainLinkDummy = styled.span`
  ${sharedMainLink}
  cursor: pointer;

  &.menu-item--active {
    color: ${(props) => props.theme.colors.brand};
  }
`

export const sharedSubLink = ({ theme }) => css`
  position: relative;
  flex: 1 1 auto;
  display: block;
  padding: 10px 0;
  color: ${theme.colors.black};
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${theme.colors.brand};
  }
`

export const SubLink = styled(Link)`
  ${sharedSubLink}

  &.menu-item--active {
    color: ${(props) => props.theme.colors.brand};
  }
`

export const SubLinkDummy = styled.span`
  ${sharedSubLink}
  cursor: pointer;

  &.menu-item--active {
    color: ${(props) => props.theme.colors.brand};
  }
`

export const MainNavigation = styled.ul`
  position: relative;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0 16px;
  max-height: calc(100dvh - 140px);
  overflow: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-height: none;
    overflow: visible;
  }
`

export const SubNavigation = styled.ul`
  position: relative;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const SubNavigationContainer = styled.div`
  position: relative;
  display: none;

  &.activated {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    pointer-events: none;
    display: block;
    position: absolute;
    height: 0;
    opacity: 0;
    left: 0;
    top: 50px;
    padding: 20px 0;
    white-space: nowrap;
    background: ${(props) => props.theme.colors.white};
    border-radius: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.24);
  }
`

export const MainNavigationItem = styled.li`
  position: relative;
  display: block;
  padding: 12px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-right: 26px;
    padding: 0;

    &:hover {
      color: ${({ theme }) => theme.colors.brand};
      text-decoration: underline;

      > div > a {
        text-decoration: none;
      }

      > div > button > svg {
        fill: ${({ theme }) => theme.colors.black};
        transform: rotate(360deg);
      }

      ${SubNavigationContainer} {
        pointer-events: all;
        display: block;
        opacity: 1;
        height: auto;
      }
    }

    /* Seperate for IE fix */
    &:focus-within {
      color: ${({ theme }) => theme.colors.brand};

      > div > a {
        text-decoration: none;
      }

      > div > button > svg {
        fill: ${({ theme }) => theme.colors.black};
      }

      ${SubNavigationContainer} {
        pointer-events: all;
        display: block;
        opacity: 1;
        height: auto;
      }
    }
  }
`

export const SubNavigationDetailContainer = styled.div`
  position: relative;
  display: none;

  &.activated {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    pointer-events: none;
    display: block;
    position: absolute;
    top: -20px;
    right: 0;
    left: auto;
    height: 0;
    padding: 20px 0;
    background: ${(props) => props.theme.colors.white};
    transform: translateX(calc(100% - 2px));
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    opacity: 0;
  }
`

export const SubNavigationItem = styled.li`
  position: relative;
  display: block;
  padding: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 20px;

    > div > button {
      width: 10px;
      margin-left: 16px;
    }

    > div > button > svg {
      transform: rotate(90deg);
    }

    &:hover {
      ${SubNavigationDetailContainer} {
        pointer-events: all;
        height: auto;
        opacity: 1;
      }
    }

    &:focus-within {
      ${SubNavigationDetailContainer} {
        pointer-events: all;
        height: auto;
        opacity: 1;
      }
    }
  }
`

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Toggle = styled.button`
  position: relative;
  z-index: 2;
  background: transparent;
  border: none;

  svg {
    fill: ${(props) => props.theme.colors.brand};
    transform: rotate(90deg);
    transition: transform 0.1s ease-in-out;
    width: 16px;
  }

  &.activated {
    svg {
      transform: rotate(180deg);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: inline-block;
    pointer-events: none;
    width: 24px;
    height: 24px;
    margin-top: 4px;
    margin-left: 3px;
    padding: 0;

    svg {
      fill: ${(props) => props.theme.colors.brand};
      transform: rotate(180deg);
      transition: transform 0.1s ease-in-out;
      width: 10px;
    }

    &.activated {
      svg {
        transform: rotate(360deg);
      }
    }
  }
`

export const StyledQuickMenu = styled(QuickMenu)(
  ({ theme }) => css`
    @media (min-width: ${theme.breakpoints.l}) {
      display: none;
    }
  `,
)
