import React from 'react'
import { Link } from 'gatsby'
import logo from '../../images/logo.svg'
import { formatSlug } from '../../utils'

import {
  FooterDataWrapper,
  Logo,
  FooterDataRow,
  FooterAddress,
  FooterListItem,
  FooterLinks,
  FooterLink,
  FooterLinkDummy,
} from './styles'

const FooterData = ({
  data: { name, address, postalCode, place, country },
  menu,
}) => {
  return (
    <FooterDataWrapper>
      <Link to="/">
        <Logo
          style={{ width: '80px', display: 'block' }}
          src={logo}
          alt="Logo Quantore"
          shadow
        />
      </Link>

      <FooterDataRow>
        <FooterAddress>
          <FooterListItem>{name}</FooterListItem>
          <FooterListItem>{address}</FooterListItem>
          <FooterListItem>{`${postalCode} ${place}`}</FooterListItem>
          <FooterListItem>{country}</FooterListItem>
        </FooterAddress>

        <FooterLinks>
          {menu &&
            menu.length > 0 &&
            menu.map(({ id, title, link }) => (
              <FooterListItem key={id}>
                {link ? (
                  <FooterLink
                    to={formatSlug(link.slug)}
                    target={
                      formatSlug(link.slug).startsWith('http')
                        ? '_blank'
                        : '_self'
                    }
                    rel={
                      formatSlug(link.slug).startsWith('http')
                        ? 'noopener noreferrer'
                        : ''
                    }
                  >
                    {title}
                  </FooterLink>
                ) : (
                  <FooterLinkDummy>{title}</FooterLinkDummy>
                )}
              </FooterListItem>
            ))}
        </FooterLinks>
      </FooterDataRow>
    </FooterDataWrapper>
  )
}

export default FooterData
