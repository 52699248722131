import React, { useState, useEffect } from 'react'
import { useMedia } from 'react-use-media'
import { graphql, useStaticQuery } from 'gatsby'
import Container from '../Container'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import { Wrapper, SkipContainer, SkipLink } from './styles'

const SkipLinks = () => (
  <Container>
    <SkipContainer id="skiplinks">
      <SkipLink
        variation="secondary"
        target="_self"
        hyperlink="#navigation"
        content="Navigation"
      />

      <SkipLink
        variation="secondary"
        target="_self"
        hyperlink="#main"
        content="Main content"
      />

      <SkipLink
        variation="secondary"
        target="_self"
        hyperlink="#footer"
        content="Footer"
      />
    </SkipContainer>
  </Container>
)

const Layout = ({ template, location, children }) => {
  const ScriptsQuery = useStaticQuery(graphql`
    query {
      allContentfulSettings(filter: { node_locale: { eq: "nl-NL" } }) {
        edges {
          node {
            ...ScriptsFragment
            ...FooterFragment
          }
        }
      }
    }
  `)

  const [isMenuToggled, setMenuToggled] = useState(false)

  const settings = ScriptsQuery.allContentfulSettings.edges[0].node
  const isMobile = useMedia({
    maxWidth: '1023px',
  })

  useEffect(() => {
    if (isMobile) {
      setMenuToggled(false)
    }
  }, [location])

  const toggleMenu = () => {
    setMenuToggled(!isMenuToggled)
  }

  return (
    <Wrapper template={template} hideScroll={isMobile && isMenuToggled}>
      <SkipLinks />

      <Header
        toggleMenu={toggleMenu}
        isMenuToggled={isMenuToggled}
        isMobile={isMobile}
      />

      <div id="main">{children}</div>

      <Footer data={settings.footerData} menu={settings.footerMenu} />
    </Wrapper>
  )
}

export default Layout
