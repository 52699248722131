import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 24px;
  gap: 24px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  border: 1px solid #e3e3e3;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.0971467);
  border-radius: 8px;
`
export const IconContainer = styled.div`
  flex: 0 0 48px;

  svg {
    display: block;
    height: 48px;
    width: auto;
    max-width: 48px;
  }
`
export const Content = styled.div`
  align-self: center;
`
export const Name = styled.div`
  font-family: AdelleBold;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
`
export const Details = styled.div`
  color: ${(props) => props.theme.colors.greyDark};
  font-size: 14px;
  font-weight: 350;
`
