import styled from 'styled-components'
import typography from '../../typography'

export const StyledTag = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['variant', 'textcolor', 'align', 'lineHeight'].includes(prop),
})`
  ${({ variant }) => typography[variant]}
  display: ${({ align }) => (align ? 'block' : 'inherit')};
  color: ${({ textcolor }) => textcolor};
  text-align: ${({ align }) => align};
  ${({ lineHeight }) =>
    lineHeight ? `line-height: ${lineHeight} !important;` : ''}
`
