import { useLocation } from '@reach/router'

export const isCooperatieSite = () => {
  const { pathname } = useLocation()

  return pathname === '/cooperatie' || pathname.startsWith('/cooperatie/')
}

export const isExtranetSite = () => {
  const { pathname } = useLocation()

  return pathname === '/extranet' || pathname.startsWith('/extranet/')
}
