import React from 'react'

import { formatSlug } from '../../utils'
import { useAuthentication } from '../Authentication'
import {
  Header,
  LinkExternal,
  LinkInternal,
  LinkText,
  List,
  ListItem,
  Logout,
  MobileLogout,
  Root,
  UserImage,
  UserName,
} from './styles'

const QuickMenu = ({ items, user, ...rest }) => {
  const { logoutPathname } = useAuthentication()

  return (
    <Root {...rest}>
      <Header>
        <UserImage src={user.picture} alt="" />
        <UserName>{user.name}</UserName>
        <Logout href={logoutPathname}>Uitloggen</Logout>
      </Header>
      <List>
        {items
          ?.filter(({ link }) => link)
          .map(({ icon, id, link, title }) => (
            <ListItem key={id}>
              {link.slug ? (
                <LinkInternal to={formatSlug(link.slug)}>
                  {icon && <img src={icon.file.url} alt={title} />}
                  <LinkText>{title}</LinkText>
                </LinkInternal>
              ) : (
                <LinkExternal
                  href={link.itemLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon.file.url} alt={title} />
                  <LinkText>{title}</LinkText>
                </LinkExternal>
              )}
            </ListItem>
          ))}
      </List>
      <MobileLogout href={logoutPathname}>Uitloggen</MobileLogout>
    </Root>
  )
}

export default QuickMenu
