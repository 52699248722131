import React, { useState, useEffect, useRef, useCallback } from 'react'
import { ChevronUp } from '../../images/svg/Icons'
import { Wrapper, Toggle, Content } from './styles'

const FaqItem = ({ title, question, answer, children, ...rest }) => {
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)

  const getHeight = useCallback(() => {
    const elementHeight = ref.current.clientHeight

    if (contentHeight !== elementHeight) {
      setContentHeight(elementHeight)
    }
  }, [ref, contentHeight])

  const onClick = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    getHeight()
    window.addEventListener('resize', getHeight)

    return () => {
      window.removeEventListener('resize', getHeight)
    }
  }, [getHeight])

  return (
    <Wrapper {...rest}>
      <Toggle isOpen={isOpen} onClick={onClick}>
        <ChevronUp />
        <span>{question || title}</span>
      </Toggle>

      <Content isOpen={isOpen} height={contentHeight}>
        <div ref={ref}>{answer || children}</div>
      </Content>
    </Wrapper>
  )
}

export default FaqItem
