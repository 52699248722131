exports.components = {
  "component---src-templates-article-page-js": () => import("./../../../src/templates/articlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-detail-page-js": () => import("./../../../src/templates/detailPage.js" /* webpackChunkName: "component---src-templates-detail-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-stores-region-overview-js": () => import("./../../../src/templates/storesRegionOverview.js" /* webpackChunkName: "component---src-templates-stores-region-overview-js" */),
  "component---src-templates-stores-region-page-js": () => import("./../../../src/templates/storesRegionPage.js" /* webpackChunkName: "component---src-templates-stores-region-page-js" */),
  "component---src-templates-supplier-js": () => import("./../../../src/templates/supplier.js" /* webpackChunkName: "component---src-templates-supplier-js" */),
  "component---src-templates-supplier-overview-js": () => import("./../../../src/templates/supplierOverview.js" /* webpackChunkName: "component---src-templates-supplier-overview-js" */)
}

