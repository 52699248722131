import React from 'react'
// We import this from dist to optimize bundle size for preview mode (as it is quite large)
import { BLOCKS } from '@contentful/rich-text-types/dist/blocks'
import { INLINES } from '@contentful/rich-text-types/dist/inlines'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Link } from 'gatsby'

import Table from './components/Table/Table'
import Downloads from './components/Downloads/Downloads'

export function renderContent(richText) {
  const options = {
    renderText: (currentText) =>
      currentText.split('\n').flatMap((text, index) => {
        const currKey = index
        return [index > 0 && <br key={currKey} />, text]
      }),
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { __typename, files } = node.data.target
        if (__typename === 'ContentfulDownloads') {
          return <Downloads files={files} />
        }
        return null
      },
      [BLOCKS.TABLE]: (_, children) => <Table>{children}</Table>,
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri.startsWith('/')) {
          return <Link to={node.data.uri}>{children}</Link>
        } else
          return (
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          )
      },
      /*eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }]*/
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const targetItem = node.data.target
        if (targetItem.__typename === 'ContentfulAsset') {
          return (
            <a
              key={targetItem.contentful_id}
              href={targetItem.file.url}
              title={targetItem.file.fileName}
              download
            >
              {children}
            </a>
          )
        }
        return null
      },
    },
  }

  return renderRichText(richText, options)
}

export function mapTypeNameToComponentName(typename) {
  return typename?.replace('Contentful', '')
}

export const formatSlug = (
  slug,
  removeLeadingSlash = false,
  stylize = false,
) => {
  if (!slug) return ''

  let formattedSlug = slug

  if (stylize) {
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
    const to = 'aaaaaeeeeeiiiiooooouuuunc------'

    formattedSlug = slug
      .split('')
      .map((letter, i) =>
        letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)),
      )

    formattedSlug = formattedSlug
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/&/g, '-en-')
      .replace(/[^\w-/]+/g, '')
      .replace(/--+/g, '-')
  }

  if (removeLeadingSlash && slug.startsWith('/')) {
    formattedSlug = slug.substr(1)
  } else if (!removeLeadingSlash && !slug.startsWith('/')) {
    formattedSlug = `/${slug}`
  }

  return formattedSlug
}
