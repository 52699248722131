import React from 'react'
import styled from 'styled-components'
import FadeIn from 'react-fade-in'

const Spacer = styled.section`
  position: relative;
  padding: 40px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 50px 0;
  }
`

const Section = ({ children, ...rest }) => (
  <FadeIn>
    <Spacer {...rest}>{children}</Spacer>
  </FadeIn>
)

export default Section
