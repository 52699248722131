import styled, { css } from 'styled-components'

export const TableWrapper = styled.div`
  margin: 22px 0;
  overflow: auto;
`

export const TableElement = styled.table(
  ({ theme: { colors, fonts } }) => css`
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    tr {
      th {
        background-color: ${colors.greyLightest};
        padding: 10px 18px;

        p {
          color: ${colors.black};
          font-family: ${fonts.adelleSans.regular};
          font-weight: 500;
          line-height: 1.78;
        }
      }

      td {
        border-bottom: 1px solid #d9d9d9;
        padding: 10px 18px;

        p {
          color: ${colors.greyDarkest};
          font-weight: 300;
          line-height: 2.83;
        }
      }
    }
  `,
)
