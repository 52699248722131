import React from 'react'
import { Normalize } from 'styled-normalize'
import { createGlobalStyle, css, ThemeProvider } from 'styled-components'

import typography from '../typography'
import theme from '../theme'
import Authentication from './Authentication'
import { fontCss } from '../fonts/fonts'

const GlobalStyle = createGlobalStyle(
  ({ theme: { breakpoints, colors, fonts } }) => [
    fontCss,
    css`
      body {
        color: ${colors.greyDarkest};
        background-color: ${colors.backgroundColor};
        font-family: ${fonts.adelleSans.regular};
        font-size: 16px;
        margin: 0;

        @media (min-width: ${breakpoints.m}) {
          font-size: 18px;
        }
      }
      a {
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
      a,
      button {
        &:focus {
          outline-color: ${colors.brand};
        }
      }
      //TODO: remove this
      .screenreader {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      //TODO: remove this
      .menu-open {
        max-height: 100vhv;
        overflow: hidden;
      }
      //TODO: remove this
      @media screen and (max-width: 480px) {
        .menu-open {
          overflow: visible;
          max-height: none;
        }
      }
      * {
        /* Workaround for issue https://github.com/gatsbyjs/gatsby/issues/38201 */
        scroll-behavior: auto; // should be smooth
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      h1 {
        ${typography.h1}
      }
      h2 {
        ${typography.h2}
      }
      h3 {
        ${typography.h3}
      }
      h4 {
        ${typography.h4}
      }
      h5 {
        ${typography.h5}
      }
      h6 {
        ${typography.h6}
      }
      p {
        ${typography.body}
        color: ${colors.black};
      }

      //TODO: figure out what this is and remove
      .tl-edges {
        overflow-y: hidden;
      }
      //TODO: there is probably a much better place for this css
      @-webkit-keyframes SlideIn {
        0% {
          opacity: 0;
          transform: translate3d(-300px, 0, 0);
        }

        40% {
          opacity: 1;
          transform: translate3d(10px, 0, 0);
        }

        50% {
          transform: translate3d(-10px, 0, 0);
        }

        to {
          transform: translate3d(0, 0, 0);
        }
      }
    `,
  ],
)

const RootElement = ({ children, hasAuth }) => (
  <ThemeProvider theme={theme}>
    <Normalize />
    <GlobalStyle />
    {hasAuth && <Authentication />}
    {children}
  </ThemeProvider>
)

export default RootElement
