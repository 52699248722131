import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useMedia } from 'react-use-media'
import { navigate, useLocation } from '@reach/router'

import logo from '../../images/logo.svg'
import Toggle from '../Toggle/Toggle'
import SiteSwitch from '../SiteSwitch/SiteSwitch'
import MainMenu from '../MainMenu/MainMenu'
import Container from '../Container'
import Theme from '../../theme'
import Settings from '../../settings'
import Notification from '../Notification/Notification'

import {
  WrappedHeader,
  FlexHeader,
  LogoWrapper,
  Logo,
  LoggedInUser,
  UserImage,
  StyledChevron,
  Actions,
  TopBar,
  StyledQuickMenu,
  SearchIcon,
  DesktopSearchLink,
  StyledLink,
  MobileSearchLink,
  LeftPane,
  LoginStateContainer,
} from './styles'
import { useAuthentication } from '../Authentication'
import { formatSlug } from '../../utils'
import {
  isCooperatieSite as isCooperatieSiteCheck,
  isExtranetSite as isExtranetSiteCheck,
} from '../../siteUtils'

const getRootUrl = (pathname) => {
  if (['cooperatie', 'extranet'].some((route) => pathname.includes(route))) {
    const splitPath = pathname.split('/')
    return splitPath[0] !== '' ? `/${splitPath[0]}` : `/${splitPath[1]}`
  }
  return '/'
}

const Header = ({ isMobile, isMenuToggled, toggleMenu }) => {
  const MenuQuery = useStaticQuery(graphql`
    query {
      allContentfulSettings(filter: { node_locale: { eq: "nl-NL" } }) {
        nodes {
          extranet
          callToActionLabel
          cooperatieMenu {
            ...MainMenuFragment
          }
          consumerMenu {
            ...MainMenuFragment
          }
          extranetMenu {
            ...MainMenuFragment
          }
          notifications {
            ...NotificationFragment
          }
          quickMenu {
            ...QuickMenuFragment
          }
        }
      }
    }
  `)
  const { pathname } = useLocation()
  const menuSettings = MenuQuery.allContentfulSettings.nodes[0]
  const {
    extranet,
    callToActionLabel,
    notifications,
    quickMenu: quickMenuData,
    cooperatieMenu: corporateMenuData,
    consumerMenu: consumerMenuData,
    extranetMenu: extranetMenuData,
  } = menuSettings
  const ref = useRef()
  const [scrollPosition, setScrollPosition] = useState(0)
  const [show, setShow] = useState(true)
  const [sticky, setSticky] = useState(false)
  const [transition, setTransition] = useState(true)
  const isDesktop = useMedia({
    minWidth: Theme.breakpoints.l,
  })
  const isExtranetSite = isExtranetSiteCheck()
  const isCooperatieSite = isCooperatieSiteCheck()

  const handleScroll = useCallback(() => {
    const boundTop = document.body.getBoundingClientRect().top
    const deltaY = -ref.current.offsetHeight - ref.current.offsetTop
    setTransition(sticky && boundTop <= deltaY - 60)
    setScrollPosition(boundTop)
    setShow(boundTop > scrollPosition)

    if (!sticky) {
      setSticky(boundTop < deltaY - 60)
    }

    if (boundTop > deltaY) {
      setSticky(false)
    }
  }, [sticky, isDesktop, scrollPosition])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const getMenu = () => {
    if (isCooperatieSite) {
      return corporateMenuData
    }
    if (isExtranetSite) {
      return extranetMenuData
    }
    return consumerMenuData
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
      return parts.pop().split(';').shift()
    }
    return undefined
  }

  const { isAuthenticated, user } = useAuthentication()
  useEffect(() => {
    // We added previousPage on window in gatsby-browser which holds all the usual props of window.location
    // @ts-ignore
    const previousPageNavigationData = window.previousPage

    // We don't add the redirect logic when the ignore_extranet_redirect cookie is present and the value is set to true
    const ignoreRedirectCookie = getCookie('ignore_extranet_redirect')

    if (
      // When we have a static user never redirect (E.g. development/preview mode)
      !process.env.GATSBY_STATIC_USER &&
      isAuthenticated &&
      ignoreRedirectCookie !== 'true' &&
      !isExtranetSite &&
      (previousPageNavigationData === undefined ||
        previousPageNavigationData?.href.indexOf('www.quantore.com') !== -1)
    ) {
      navigate(formatSlug('/extranet'))
    }
  }, [isAuthenticated])

  return (
    <TopBar>
      {isAuthenticated &&
        notifications?.map(
          ({ badgeText, id, link, linkText, title, variant }) => (
            <Notification
              badgeText={badgeText}
              key={id}
              link={link}
              linkText={linkText}
              title={title}
              variant={variant}
            />
          ),
        )}
      <WrappedHeader
        id="header"
        isMenuToggled={isMenuToggled}
        show={show}
        ref={ref}
        sticky={sticky}
        transition={transition}
      >
        {Settings.hasSiteSwitch &&
          (!isMobile || (isMenuToggled && isMobile)) && <SiteSwitch />}

        <Container>
          <FlexHeader sticky={sticky}>
            <LeftPane>
              <Toggle toggleActive={isMenuToggled} onClickAction={toggleMenu} />
              <MobileSearchLink to="/extranet/search">
                <SearchIcon />
              </MobileSearchLink>
            </LeftPane>

            <LogoWrapper
              sticky={sticky}
              centered={Settings.headerLogoMobileCentered}
            >
              <Link to={getRootUrl(pathname)}>
                {Settings.headerLogoOverflowMode === 'none' ? (
                  <Logo src={logo} alt="Logo Biaretto" sticky={sticky} />
                ) : (
                  <Logo src={logo} alt="Logo Quantore" sticky={sticky} shadow />
                )}
              </Link>
            </LogoWrapper>
            {!isMobile || (isMenuToggled && isMobile) ? (
              <MainMenu
                data={getMenu()}
                sticky={sticky}
                quickMenuItems={quickMenuData}
                user={user}
              />
            ) : null}

            {callToActionLabel && (
              <Actions>
                {isAuthenticated && (
                  <>
                    <DesktopSearchLink to="/extranet/search">
                      <SearchIcon />
                    </DesktopSearchLink>

                    <LoginStateContainer>
                      <LoggedInUser
                        type="button"
                        id="quick-menu-button"
                        aria-haspopup="true"
                        aria-controls="quick-menu"
                        onClick={toggleMenu}
                      >
                        <UserImage src={user.picture} alt="" />
                        <StyledChevron />
                      </LoggedInUser>
                      <StyledQuickMenu
                        id="quick-menu"
                        items={quickMenuData}
                        user={user}
                      />
                    </LoginStateContainer>
                  </>
                )}

                {!isAuthenticated && (
                  <StyledLink
                    variation={Settings.hasSiteSwitch ? 'secondary' : 'primary'}
                    hyperlink={extranet}
                    content={callToActionLabel}
                  />
                )}
              </Actions>
            )}
          </FlexHeader>
        </Container>
      </WrappedHeader>
    </TopBar>
  )
}

export default Header
