import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FooterTop from './FooterTop'
import FooterData from './FooterData'
import FooterSocial from './FooterSocial'
import Container from '../Container'
import Spacer from '../Spacer'

import { StyledFooter, FooterContainer } from './styles'
import { isCooperatieSite, isExtranetSite } from '../../siteUtils'

const Footer = ({ data, menu }) => {
  const FooterQuery = useStaticQuery(graphql`
    query {
      allContentfulSettings(filter: { node_locale: { eq: "nl-NL" } }) {
        nodes {
          footerSubMenus {
            ...FooterSubMenuFragment
          }
          extranetFooterSubMenus {
            ...FooterSubMenuFragment
          }
          cooperatieFooterSubMenus {
            ...FooterSubMenuFragment
          }
        }
      }
    }
  `)

  const footerSettings = FooterQuery.allContentfulSettings.nodes[0]
  const { footerSubMenus, extranetFooterSubMenus, cooperatieFooterSubMenus } =
    footerSettings

  const {
    facebookLink,
    instagramLink,
    pinterestLink,
    youTubeLink,
    linkedInLink,
  } = data

  const menuData = () => {
    if (isCooperatieSite()) return cooperatieFooterSubMenus
    if (isExtranetSite()) return extranetFooterSubMenus
    return footerSubMenus
  }

  return (
    <StyledFooter id="footer" aria-label={menu?.title}>
      {menuData() && menuData()?.length > 0 && (
        <FooterTop submenus={menuData()} />
      )}

      <Spacer>
        <Container>
          <FooterContainer>
            <FooterData data={data} menu={menu?.subitems} />

            <FooterSocial
              facebook={facebookLink}
              instagram={instagramLink}
              pinterest={pinterestLink}
              youtube={youTubeLink}
              linkedin={linkedInLink}
            />
          </FooterContainer>
        </Container>
      </Spacer>
    </StyledFooter>
  )
}

export default Footer
