import React from 'react'
import { useMedia } from 'react-use-media'
import { useLocation } from '@reach/router'

import { formatSlug } from '../../utils'
import { ChevronUp } from '../../images/svg/Icons'
import Theme from '../../theme'
import {
  ItemWrapper,
  MainLink,
  MainLinkDummy,
  MainNavigation,
  MainNavigationItem,
  MenuWrapper,
  StyledQuickMenu,
  SubLink,
  SubLinkDummy,
  SubNavigation,
  SubNavigationContainer,
  SubNavigationDetailContainer,
  SubNavigationItem,
  Toggle,
  ToggleText,
} from './styles'
import { useAuthentication } from '../Authentication'

const Menu = ({ data, quickMenuItems, sticky, user }) => {
  const { isAuthenticated } = useAuthentication()
  const { pathname } = useLocation()
  const isMobile = useMedia({
    maxWidth: Theme.breakpoints.l,
  })

  const toggleItem = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const toggleElement = event.currentTarget
    const toggleButton =
      toggleElement.children[toggleElement.children.length - 1]
    const subnavigationElement = toggleElement.nextSibling

    if (subnavigationElement.classList.contains('activated')) {
      toggleButton.classList.remove('activated')
      subnavigationElement.classList.remove('activated')
    } else {
      toggleButton.classList.add('activated')
      subnavigationElement.classList.add('activated')
    }
  }

  const startsWith = (string, stringToCompare) => {
    return string ? string.indexOf(stringToCompare, 0) === 0 : null
  }

  const formattedPath = (originalPath, pathToCompare) => {
    return startsWith(originalPath, pathToCompare)
      ? originalPath
      : `/${originalPath}`
  }

  const currentPath = formattedPath(pathname, '/')
  const corporatePaths = ['/co%C3%B6peratie', '/cooperatie']

  const isMainNavItemActive = (link, childLinks) => {
    const cleanLink = formattedPath(link?.slug, '/')
    const childLinksArray = childLinks?.map((item) =>
      formattedPath(item?.link?.slug, '/'),
    )
    const hasActiveSubItem = childLinksArray?.some((path) =>
      currentPath.startsWith(path),
    )
    const isCorporateRootItem = corporatePaths.some(
      (path) => currentPath === path,
    )
    const active =
      (!isCorporateRootItem && cleanLink === currentPath) || hasActiveSubItem

    if (active) {
      return 'menu-item--active'
    }
    return null
  }

  const isSubNavItemActive = (link) => {
    const cleanLink = formattedPath(link?.slug, '/')
    const nonCorporateLink = startsWith(cleanLink, '/cooperatie')
      ? formattedPath(cleanLink.replace('/cooperatie', ''), '/')
      : cleanLink

    const nonCorporatePath = startsWith(currentPath, '/cooperatie')
      ? formattedPath(currentPath.replace('/cooperatie', ''), '/')
      : currentPath

    const active = startsWith(nonCorporatePath, nonCorporateLink)

    if (active) {
      return 'menu-item--active'
    }
    return null
  }

  const getDropdownProps = (subitems) => {
    if (isMobile && subitems) {
      return { onClick: toggleItem }
    }

    return { tabIndex: -1 }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const MenuItemRendered = ({ link, title, childLinks }) => {
    if (link) {
      return (
        <MainLink
          fade="true"
          duration={2}
          to={formatSlug(link.slug)}
          className={isMainNavItemActive(link, childLinks)}
        >
          {title}
        </MainLink>
      )
    } else {
      return <MainLinkDummy>{title}</MainLinkDummy>
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const SubMenuItemRendered = ({ link, title }) => {
    if (link) {
      return (
        <SubLink
          fade="true"
          duration={2}
          to={formatSlug(link.slug)}
          className={isSubNavItemActive(link)}
        >
          {title}
        </SubLink>
      )
    } else {
      return <SubLinkDummy>{title}</SubLinkDummy>
    }
  }

  if (!data) return null

  return (
    <MenuWrapper sticky={sticky}>
      <MainNavigation>
        {data.subitems.length > 0 &&
          data.subitems.map(({ id, link, subitems, title }) => (
            <MainNavigationItem key={id}>
              <ItemWrapper {...getDropdownProps(subitems)}>
                {subitems ? (
                  <>
                    {isMobile ? (
                      <ToggleText
                        className={isMainNavItemActive(link, subitems)}
                      >
                        {title}
                      </ToggleText>
                    ) : (
                      <MenuItemRendered
                        link={link}
                        title={title}
                        childLinks={subitems}
                      />
                    )}
                    <Toggle aria-label="Toggle">
                      <ChevronUp />
                    </Toggle>
                  </>
                ) : (
                  <MenuItemRendered link={link} title={title} />
                )}
              </ItemWrapper>
              {subitems && (
                <SubNavigationContainer>
                  <SubNavigation>
                    {subitems.map(
                      ({
                        id: mainId,
                        link: mainLink,
                        title: mainTitle,
                        subitems: mainSubItems,
                      }) => (
                        <SubNavigationItem key={`${id}-${mainId}`}>
                          <ItemWrapper {...getDropdownProps(mainSubItems)}>
                            <SubMenuItemRendered
                              link={mainLink}
                              title={mainTitle}
                              parent={title}
                            />
                            {mainSubItems && (
                              <Toggle aria-label="Toggle">
                                <ChevronUp />
                              </Toggle>
                            )}
                          </ItemWrapper>
                          {mainSubItems && (
                            <SubNavigationDetailContainer>
                              <SubNavigation>
                                {mainSubItems.map(
                                  ({
                                    id: subId,
                                    link: subLink,
                                    subitmes: subSubItems,
                                    title: subTitle,
                                  }) => (
                                    <SubNavigationItem
                                      key={`${id}-${mainId}-${subId}`}
                                    >
                                      <ItemWrapper
                                        {...getDropdownProps(subSubItems)}
                                      >
                                        <SubMenuItemRendered
                                          link={subLink}
                                          title={subTitle}
                                        />
                                        {subSubItems && (
                                          <Toggle aria-label="Toggle">
                                            <ChevronUp />
                                          </Toggle>
                                        )}
                                      </ItemWrapper>
                                    </SubNavigationItem>
                                  ),
                                )}
                              </SubNavigation>
                            </SubNavigationDetailContainer>
                          )}
                        </SubNavigationItem>
                      ),
                    )}
                  </SubNavigation>
                </SubNavigationContainer>
              )}
            </MainNavigationItem>
          ))}
      </MainNavigation>
      {isAuthenticated && (
        <StyledQuickMenu items={quickMenuItems} user={user} />
      )}
    </MenuWrapper>
  )
}

export default Menu
