import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  font-size: 18px;
  line-height: 32px;
  margin-top: -1px;
`

export const Title = styled.h3`
  margin: 0 0 20px;
  line-height: 58px;
`

export const Toggle = styled.button(
  ({ isOpen, theme: { colors, fonts } }) => css`
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    background: none;
    border: none;
    border-top: 1px solid ${colors.greyLighter};
    font-family: ${isOpen ? fonts.adelle.bold : fonts.adelle.regular};
    text-align: left;
    transition: padding 0.2s ease;

    &:hover {
      background-color: ${colors.greyLightest};
    }

    span {
      flex: 1 0;
    }

    svg {
      flex: 0 1 auto;
      width: 16px;
      height: 10px;
      margin: 0 20px 0 16px;
      fill: ${colors.brand};
      transition: transform 0.2s ease;
      transform: ${isOpen ? 'rotate(0)' : 'rotate(-180deg)'};
    }
  `,
)

export const Content = styled.div(
  ({ height, isOpen, theme: { colors, fonts } }) => css`
    height: ${isOpen ? `${height}px` : 0};
    overflow: hidden;
    transition: height 0.2s ease;
    border-bottom: 1px solid ${colors.greyLighter};

    > div {
      padding: 4px 0 20px;
      padding-left: 52px;
    }

    p {
      color: ${colors.greyDarkest};
      font-family: ${fonts.adelleSans.light};
      line-height: 32px;

      &:last-child {
        margin: 0;
      }
    }
  `,
)
export const Button = styled(Link)(
  ({ theme: { breakpoints, colors } }) => css`
    align-items: center;
    color: ${colors.brand};
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 21px;
    margin-top: 30px;
    text-decoration: none;

    svg {
      margin-left: 12px;
      width: 23px;
      fill: ${colors.brand};
    }

    @media (min-width: ${breakpoints.l}) {
      margin-top: 39px;
    }
  `,
)
