import React from 'react'
import { Container, Content, Details, IconContainer, Name } from './styles'

const readableFileType = (mimeType) => {
  if (mimeType.startsWith('image/')) {
    return 'Afbeelding'
  }
  if (mimeType.startsWith('text/')) {
    if (mimeType === 'text/html') {
      return 'Webpagina'
    }
    if (mimeType === 'text/csv') {
      return 'CSV'
    }
    return 'Tekstdocument'
  }
  if (mimeType.startsWith('audio/')) {
    return 'Audio'
  }
  if (mimeType.startsWith('video/')) {
    return 'Video'
  }
  if (mimeType.startsWith('application/')) {
    // Any kind of binary that doesn't fall into another type
    if (mimeType === 'application/pdf') {
      return 'PDF'
    }
    if (mimeType === 'application/zip') {
      return 'Zip'
    }
    if (
      mimeType.includes('msword') ||
      mimeType.includes('vnd.openxmlformats-officedocument.wordprocessingml') ||
      mimeType.includes('vnd.ms-word')
    ) {
      // doc, docx and all variants
      return 'Microsoft Word document'
    }
    if (mimeType.includes('opendocument.text')) {
      // Open office
      return 'Tekstdocument'
    }
    if (mimeType.includes('keynote')) {
      // Mac
      return 'Keynote'
    }
    if (
      mimeType.includes('vnd.ms-excel') ||
      mimeType.includes('vnd.openxmlformats-officedocument.spreadsheetml')
    ) {
      // xls, xlsx and all variants
      return 'Microsoft Excel spreadsheet'
    }
    if (mimeType.includes('opendocument.spreadsheet')) {
      // Open Office
      return 'Spreadsheet'
    }
    if (
      mimeType.includes('vnd.ms-powerpoint') ||
      mimeType.includes('vnd.openxmlformats-officedocument.presentationml')
    ) {
      return 'Powerpoint presentatie'
    }
    return 'Onbekend type'
  }
  return 'Onbekend type'
}
const readableFileSize = (size, locale = 'nl') => {
  if (size < 1000) {
    return `${size} bytes`
  }
  /* eslint-disable no-param-reassign */
  size /= 1024
  if (size < 1000) {
    return `${Math.round(size).toLocaleString(locale)} KB`
  }
  size /= 1024
  if (size < 1000) {
    return `${Math.round(size).toLocaleString(locale)} MB`
  }
  size /= 1024
  return `${Math.round(size).toLocaleString(locale)} GB`
}

const File = ({
  icon,
  name,
  type,
  size,
  href,
  download,
  target = '_blank',
  ...others
}) => (
  <a href={href} target={target} download>
    <Container {...others}>
      <IconContainer>{icon}</IconContainer>
      <Content>
        <Name>{name}</Name>
        <Details>
          {readableFileType(type)} {readableFileSize(size)}
        </Details>
      </Content>
    </Container>
  </a>
)

export default File
