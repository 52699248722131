import React from 'react'

import File from '../File/File'
import { ImageFile, PdfFile } from '../../images/svg/Icons'
import { FileList, FileListItem, Image } from './styles'

const Icon = ({ contentType, url, fileName }) => {
  if (contentType.includes('pdf')) return <PdfFile />
  if (contentType.startsWith('image')) return <Image src={url} alt={fileName} />
  return <ImageFile />
}

const Downloads = ({ files }) => (
  <FileList>
    {files.map(
      ({
        id,
        file: {
          contentType,
          url,
          fileName,
          details: { size },
        },
      }) => (
        <FileListItem key={id}>
          <File
            icon={
              <Icon contentType={contentType} url={url} fileName={fileName} />
            }
            name={fileName}
            href={url}
            type={contentType}
            size={size}
            download
          />
        </FileListItem>
      ),
    )}
  </FileList>
)

export default Downloads
