import styled from 'styled-components'
import { Link } from 'gatsby'
import Container from '../Container'
import Theme from '../../theme'

export const StyledFooter = styled.footer`
  position: relative;
  display: block;
  background-color: ${(props) => props.theme.colors.backgroundColor};
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-top: 50px;
  }

  > section {
    border-top: 1px solid #e4e4e4;

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      padding: 30px 0 50px;
    }
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const FooterDataWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    flex-direction: row;
  }
`

export const FooterDataRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    align-items: flex-start;
    margin-left: 24px;
    justify-content: center;
  }
`

export const FooterAddress = styled.ul`
  padding: 0;
  list-style: none;
  text-align: center;
  color: ${(props) => props.theme.colors.grey};
  font-size: 14px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin: 0;
    font-size: 16px;
    color: ${(props) => props.theme.colors.greyDark};
  }
`

export const FooterLinks = styled.ul`
  list-style: none;
  font-size: 14px;
  padding: 0;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin: 0;
  }
`

export const FooterListItem = styled.li`
  position: relative;
  display: inline-block;
  padding: 5px 10px;

  &:not(:first-child)::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 12px;
    background-color: ${(props) => props.theme.colors.grey};
  }
`

export const FooterLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.grey};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.brand};
  }
`

export const FooterLinkDummy = styled.span`
  color: ${(props) => props.theme.colors.grey};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.brand};
    text-decoration: underline;
  }
`

export const Logo = styled('img').withConfig({
  shouldForwardProp: (prop) => !['shadow'].includes(prop),
})`
  width: auto;
  height: 100%;
  margin-top: -45px;
  ${({ shadow }) =>
    shadow && `filter: drop-shadow(0 12px 12px rgba(0, 0, 0, .2));`}

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-top: 0;
  }
`

export const SubFooterWrapper = styled.div`
  padding: 0 0 38px;
  background-color: ${({ theme }) => theme.colors.greyLightest};

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 54px 0 100px;
  }
`

export const SubFooterRow = styled(Container)`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li:not(:last-child) {
    padding-bottom: 0.5rem;
  }

  li a {
    display: block;

    &:hover,
    &:active,
    &:focus {
      &,
      span {
        color: ${({ theme }) => theme.colors.brand};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    > div {
      margin-right: -16px;
      margin-left: -16px;

      &:first-child > button {
        border-top: 0;
      }

      &:last-child > div {
        border-bottom: 0;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    ul {
      margin: 10px 0 0;
    }
  }
`

export const SubFooterCol = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    flex: 1 1 auto;
    width: calc(33.3333% - 40px);
  }
`

export const SocialList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
`

export const SocialListItem = styled.li`
  list-style: none;
  display: flex;
  padding: 10px 5px;
`

const SocialLink = `
  display: flex;
  width: 42px;
  height: 42px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-decoration: none;

  svg {
    fill: ${Theme.colors.white};
  }
`

export const FacebookLink = styled.a`
  ${SocialLink}
  background-color: ${(props) => props.theme.colors.blue};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.blue};

    svg {
      fill: ${(props) => props.theme.colors.blue};
    }
  }
`

export const InstagramLink = styled.a`
  ${SocialLink}
  background-color: ${(props) => props.theme.colors.pink};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.pink};

    svg {
      fill: ${(props) => props.theme.colors.pink};
    }
  }
`

export const LinkedInLink = styled.a`
  ${SocialLink}
  background-color: ${(props) => props.theme.colors.turquoise};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.turquoise};

    svg {
      fill: ${(props) => props.theme.colors.turquoise};
    }
  }
`

export const PinterestLink = styled.a`
  ${SocialLink}
  background-color: ${(props) => props.theme.colors.brand};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.brand};

    svg {
      fill: ${(props) => props.theme.colors.brand};
    }
  }
`

export const YoutubeLink = styled.a`
  ${SocialLink}
  background-color: ${(props) => props.theme.colors.orange};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.orange};

    svg {
      fill: ${(props) => props.theme.colors.orange};
    }
  }
`
